import Big from "big.js";

export const convertDecimals =
  (decimals?: number | undefined | null) => (val?: Big | number | undefined | null) => {
    if (decimals && val) {
      return Big(val)
        .div(10 ** decimals)
        .toString();
    } else return null;
  };

export const bigNumberConverter = (value: number) => {
  if (value > 1_000_000_000_000)
    return `${(Math.round(value / 10_000_000_000_000) / 100).toLocaleString()}Q`;
  else if (value > 1_000_000_000_000) return `${Math.round(value / 10_000_000_000) / 100}T`;
  else if (value > 1_000_000_000) return `${Math.round(value / 10_000_000) / 100}B`;
  else if (value > 1_000_000) return `${Math.round(value / 10_000) / 100}M`;
  else if (value > 1_000) return `${Math.round(value / 10) / 100}K`;
  else if (value > 0.1) return `${Math.round(value * 100) / 100}`;
  else if (value > 0.0001) return `${Math.round(value * 10000) / 10000}`;
  else return `${Math.round(value * 1_000_000) / 1_000_000}`;
};

export const bigNumberConverter2 = (value: string | number) => {
  const bigNumber = Big(value);
  const result = Number(
    Big(value).toPrecision(bigNumber.gt(10 ** 9) ? 9 : bigNumber.gt(10 ** 6) ? 6 : 3)
  );
  if (bigNumber.lt(1)) return bigNumberConverter(bigNumber.toNumber());
  return Number(result).toLocaleString();
};

export const btcOrSatPrice = (value: number) => {
  if (value > 1_000_000) return `${Math.round(value / 10_000) / 100}M`;
  else if (value > 1_000) return `${Math.round(value / 10) / 100}K`;
  else if (value > 0.1) return `${Math.round(value * 100) / 100}`;
  else if (value >= 0.0001) return `${Math.round(value * 10000) / 10000}`;
  else return `${Math.round(value * 100_000_000)}`; // This should be displayed as Sats
};

export const usdConverter = ({
  value,
  rate,
}: {
  value: string | number | null | undefined;
  rate: string | undefined;
}) => (value && rate ? bigNumberConverter2(Big(value).mul(rate).toString()) : undefined);
